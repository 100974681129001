export class Faq {
	private itemElms: NodeListOf<HTMLLIElement>

	constructor() {
		this.itemElms = document.querySelectorAll('.eventTop_faq_item')

		this.init()
	}

	private init() {
		if (this.itemElms) {
			this.itemElms.forEach(elm => {
				const btnElm = elm.querySelector('.eventTop_faq_item_opener')
				const inner = elm.querySelector('.eventTop_faq_item_inner')
				const setInnerHeight = () => {
					elm.style.setProperty(
						'--inner-height',
						`${inner?.clientHeight}px`,
					)
				}

				setInnerHeight()

				const btnInnerText = btnElm?.querySelector(
					'.eventTop_faq_item_opener_text',
				) as HTMLElement

				btnElm?.addEventListener('click', () => {
					const prev = elm.getAttribute('data-open')

					const next = prev === 'true' ? false : true
					elm.setAttribute('data-open', `${next}`)
					setInnerHeight()

					btnInnerText.textContent = next ? '閉じる' : 'くわしく見る'
				})
			})
		}
	}

	public onResize() {
		if (this.itemElms) {
			this.itemElms.forEach(elm => {
				const isOpen = elm.getAttribute('data-open') === 'true'

				if (isOpen == false) return

				const inner = elm.querySelector('.eventTop_faq_item_inner')
				elm.style.setProperty(
					'--inner-height',
					`${inner?.clientHeight}px`,
				)
			})
		}
	}
}
