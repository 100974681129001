<template>
    <system-page :page-settings="page">
        <nav class="faq-nav">
            <ul>
                <li><router-link :to="{hash: '#h1'}">全般</router-link></li>
                <li><router-link :to="{hash: '#h2'}">アカウント登録</router-link></li>
                <li><router-link :to="{hash: '#h3'}">アカウント変更</router-link></li>
                <li><router-link :to="{hash: '#h4'}">ログイン</router-link></li>
                <li><router-link :to="{hash: '#h5'}">イベントの申込</router-link></li>
                <li><router-link :to="{hash: '#h6'}">マイページ</router-link></li>
                <li><router-link :to="{hash: '#h7'}">チャンピオンシップ<br>ポイント</router-link></li>
                <li><router-link :to="{hash: '#h8'}">退会</router-link></li>
                <!-- <li><router-link :to="{hash: '#h9'}">データ移行</router-link></li> -->
                <li><router-link :to="{hash: '#h10'}">その他</router-link></li>
            </ul>
        </nav>
        <section class="faq-section">
            <h2 id="h1" class="c-title c-title-h2">全般</h2>
            <list-item question="「ポケモンカードゲーム プレイヤーズクラブ」とは何ですか。">
                <p>「ポケモンカードゲーム」のイベント情報の閲覧、イベントエントリーができる会員制サイトです。会員登録することで、イベントへの応募、デッキの登録、イベントで獲得した「チャンピオンシップポイント」を貯めることなどができるようになります。</p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h2" class="c-title c-title-h2">アカウント登録</h2>
            <list-item question="アカウント登録時、またはアカウント登録後に費用はかかりますか。">
                <p>登録料・会費は無料です。ただし、接続に関わる通信費等や、イベント参加するための交通費等は、お客様でご負担ください。</p>
            </list-item>
            <list-item question="アカウント登録に必要なものは何ですか。">
                <p>
                    メールアドレスと電話番号が必要です。
                </p>
            </list-item>
            <list-item question="アカウント登録に必要な情報を教えてください。">
                <p>生年月日、ニックネーム、氏名、メールアドレス、携帯電話番号、性別、お住まいの住所情報となります。なお、氏名、生年月日は、一度登録すると変更できません。登録する際は十分にご注意ください。</p>
            </list-item>
            <list-item question="アカウント登録に年齢制限はありますか。">
                <p>アカウント登録に年齢制限はございません。ただし、18歳未満の方は、アカウント登録の際に保護者の同意が必要になります。</p>
            </list-item>
            <list-item question="24時間たっても「仮登録完了のお知らせ」が届きません。">
                <p>「仮登録完了のお知らせ」が、ご登録のメールアドレスに届かない場合には、次の原因が考えられます。ご利用環境をご確認のうえ、再度登録のお手続きをお願いいたします。</p>
                <ol class="c-list c-list-ol dot">
                    <li>ご登録の際にご入力いただいたメールアドレスが誤っていた可能性がございます。ご利用のメールアドレスをご確認のうえ、誤りのないようご入力ください。</li>
                    <li>迷惑メール防止機能の設定により、正しく受信できなかった可能性がございます。「info@mail.players.pokemon-card.com」からのメールが受信できるように、設定をご変更ください。</li>
                    <li>ご登録の際にご入力いただいたメールアドレスが、すでに「ポケモンカードゲーム プレイヤーズクラブ」に登録されている可能性がございます。</li>
                </ol>
            </list-item>
            <list-item question="「「ポケモンカードゲーム プレイヤーズクラブ」本登録のお願い」に記載されたURLをクリックしても、ページが存在しません。">
                <p>
                    「「ポケモンカードゲーム プレイヤーズクラブ」メールアドレス認証完了のお知らせ／会員情報登録のお願い」に記載されているアカウント登録用URLの有効期限は24時間となります。
                    そのため、新規登録用URLの有効期限が過ぎている可能性が考えられます。
                    大変お手数をおかけいたしますが、改めて新規登録の手続きをお試しいただき、新しくお手元に届いたメールに記載されているURLからご登録いただきますようお願いいたします。
                </p>
            </list-item>
            <list-item question="「SMS認証」とはなんですか。">
                <p>
                    登録いただいた携帯電話番号宛に送信されたSMSに記載の数字を使った認証方式です。
                    SMS認証をしていただかない限り、イベントのエントリーを行うことができません。
                    一度認証を行った携帯電話番号は、他のアカウントと連携させることはできません。ただし、ジュニアリーグ、シニアリーグに所属するプレイヤーは、保護者と同じ携帯電話番号を用いてSMS認証を行うことが可能です。
                </p>
                <p>会員登録後、SMS認証が完了していない場合は、SMS認証画面から、認証を行なってください。</p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h3" class="c-title c-title-h2">アカウント変更</h2>
            <list-item question="登録内容を変更するにはどうすればよいですか。">
                <p>
                    マイページの「ユーザー情報変更」画⾯から変更可能です。<br>
                    ただし、改姓等により「⽒名」の情報を修正したい場合は、「<a href="https://www.pokemon-card.com/contact/" target="_blank" class="c-link">ポケモンカードゲーム サポートデスク</a>」宛にお問い合わせください。<br>
                    ※⽣年⽉⽇の変更は原則不可となります。
                </p>
            </list-item>
            <list-item question="プレイヤーIDは変更できますか。">
                <p>ご登録の際に発行されたプレイヤーIDは、変更することができません。ご了承ください。</p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h4" class="c-title c-title-h2">ログイン</h2>
            <list-item question="ログインができません。">
                <p>
                    入力いただいた、「メールアドレスまたはプレイヤーID」および、「パスワード」に間違いがないか、今一度ご確認ください。
                    パスワードが不明な場合は、「パスワードを忘れた方」より、パスワードの再設定を行なってください。
                    それでも解決できない場合は、お手数ですが、「<a href="https://www.pokemon-card.com/contact/" target="_blank" class="c-link">ポケモンカードゲーム サポートデスク</a>」まで、お問い合わせください。
                </p>
            </list-item>
            <list-item question="プレイヤーIDとはなんですか。">
                <p>ご登録時にプレイヤーに個別に与えられる10桁の固有数字です。自動で割り振られ、変更はできません。マイページから確認することができます。このプレイヤーIDは、大会での本人確認などに使用されます。</p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h5" class="c-title c-title-h2">イベントの申込</h2>
            <list-item question="エントリーをキャンセルすることはできますか。">
                <p>マイページの「出場予定のイベント」から、エントリーをキャンセルすることができます。</p>
            </list-item>
            <list-item question="エントリーのキャンセルは、いつまですることができますか。">
                <p>イベント開催前日の18時59分まで可能です。以降のキャンセルはできません。</p>
            </list-item>
            <list-item question="イベントにエントリーすることができません。">
                <p>
                    以下の原因が考えられます。<br>
                    ・プレイヤーズクラブにログインしていない。<br>
                    ・SMS認証が完了していない。<br>
                    ・ご自身の所属リーグとエントリーしようとしているイベントのリーグ区分が異なる。<br>
                    また、エントリー可能回数に制限がかかっているイベントがあるので、参加を希望されているイベントのエントリー制限回数に達していないかご確認ください。
                </p>
            </list-item>
            <list-item
                id="reservoir"
                question="「リザーバー応募」とはなんですか。"
            >
                <p>
                    抽選応募で当選したプレイヤーからイベント出場キャンセルが出た場合に、リザーバーとして当選しているプレイヤーが自動的に繰り上げ当選する仕組みがあり、そのリザーバーへの応募のことです。<br>
                    リザーバーから繰り上げ当選確定時には、メールとマイページの「出場予定のイベント」にてお知らせいたします。
                </p>
            </list-item>
            <list-item
                id="priority"
                question="イベント応募時の「第1希望」とはなんですか？"
            >
                <p>
                    「第1希望」を「ON」にしたエントリーは、自分が応募しているその他のエントリーよりも優先して当選しやすくなります。但し、本機能は当選を確約するものではございません。<br>
                    各シーズンごとのシティリーグなど、複数の会場で行われる同じ種類のイベントへの抽選応募時に、設定できます。<br>
                    抽選応募受付中であれば、マイページの「出場予定のイベント」から、いつでも「第1希望」の設定を変更することが可能です。
                </p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h6" class="c-title c-title-h2">マイページ</h2>
            <list-item question="マイページを「公開」に設定した場合、どんな情報が公開されますか。">
                <p>
                    ・イベント成績<br>
                    ・アバター<br>
                    ・マイデッキ（※「公開」に設定している場合のみ）<br>
                    が公開されます。
                </p>
            </list-item>
            <list-item question="アバターはどうやって変更しますか。">
                <p>マイページの「アバター変更」から変更することができます。</p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h7" class="c-title c-title-h2">チャンピオンシップポイント</h2>
            <list-item question="チャンピオンシップポイントとはなんですか。">
                <p>チャンピオンズリーグの上位入賞などで付与されるポイントです。自身のポイントはマイページで確認することができます。年間で一定ポイント以上を貯めることで、ポケモンワールドチャンピオンシップス等への出場権利を獲得することができます。詳しくはこちらをご覧ください。</p>
                <template #sub>
                    <p>チャンピオンシップポイント制度について</p>
                    <p>
                        <a href="http://www.pokemon-card.com/info/2000/20000101_000811.html" class="c-link" target="_blank">http://www.pokemon-card.com/info/2000/20000101_000811.html</a>
                    </p>
                </template>
            </list-item>
            <list-item question="チャンピオンシップポイントはいつ反映されますか。">
                <p>イベントが終了してから、およそ2週間ほどでマイページに反映されます。</p>
            </list-item>
            <list-item question="海外のポケモンカードゲームのイベントで、チャンピオンシップポイントを使うことはできますか。">
                <p>いいえ、使うことはできません。</p>
            </list-item>
        </section>
        <section class="faq-section">
            <h2 id="h8" class="c-title c-title-h2">退会</h2>
            <list-item question="「ポケモンカードゲーム プレイヤーズクラブ」を退会したいのですが、どのようにしたらいいですか。">
                <p>マイページメニューの「ユーザー情報」画面から「退会する」ボタンを選択いただくことで退会手続きができます。</p>
            </list-item>
            <list-item question="退会後、登録情報はどうなりますか。">
                <p>退会後、ユーザ情報は即時に削除されます。</p>
            </list-item>
            <list-item question="退会後、以前登録したメールアドレスで登録することはできますか？">
                <p>はい、できます。退会済みのメールアドレスで再度ご登録される場合、新規アカウントでの登録となります。</p>
            </list-item>
        </section>
        <!--
        <section class="faq-section">
            <h2 id="h9" class="c-title c-title-h2">データ移行</h2>
            <list-item question="過去のイベント成績を引き継ぐことはできますか。">
                <p>旧プレイヤーズクラブからアカウントを引き継いだ場合、過去のイベント成績を引き継ぐことが可能です。</p>
            </list-item>
            <list-item question="過去に獲得したトレーナーレコードはどうなりましたか。">
                <p>トレーナーレコードおよびトレーナーレベルは廃止になりました。</p>
            </list-item>
            <list-item question="旧プレイヤーズクラブからアカウントを引き継がずに、新規アカウントを作成することはできますか。">
                <p>旧プレイヤーズクラブに登録したメールアドレスでは、新規会員登録を行うことはできません。お手数ですが「会員移行」より、移行の手続きを行なってください。</p>
            </list-item>
            <list-item question="旧プレイヤーズクラブに登録されている情報はどうなりますか。">
                <p>一定期間経過後に削除されます。</p>
            </list-item>
        </section>
        -->
        <section class="faq-section">
            <h2 id="h10" class="c-title c-title-h2">その他</h2>
            <list-item question="「よくあるご質問」を見ても解決しません。">
                <p>「ポケモンカードゲーム サポートデスク」まで、お問い合わせください。</p>
                <template #sub>
                    <p>「ポケモンカードゲーム サポートデスク」</p>
                    <p>
                        <a href="https://www.pokemon-card.com/contact/" class="c-link" target="_blank">https://www.pokemon-card.com/contact/</a>
                    </p>
                </template>
            </list-item>
        </section>
        <div class="faq-section u-text-center">
            <router-link
                :to="{name: 'EventTop'}"
                class="c-btn c-btn-primary-outline"
            >
                イベントトップへ
            </router-link>
        </div>
    </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
import ListItem from './component/ListItem.vue'

export default {
    name: 'FaqPage',
    components: {
        SystemPage,
        ListItem,
    },
    data() {
        return {
            page: {
                title: 'プレイヤーズクラブよくある質問',
                desc: 'ポケモンカードゲーム プレイヤーズクラブ よくある質問のページ。',
                breadcrumb: [
                    { name: 'イベント', path: '/' },
                ],
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.faq-nav {
    & > ul {
        & > li {
            & > a {
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 49px;
                text-align: center;
                font-size: 13px;
                line-height: 18px;
                font-weight: 500;
                color: g.$color-font;
                background: g.$color-white;
                border: 1px solid g.$color-black-a025;
                border-radius: 4px;
                @include g.pc {
                    &:hover {
                        text-decoration: none;
                        border: 1px solid g.$color-black-a060;
                        box-shadow: 2px 2px 8px rgba(g.$color-black, .12);
                    }
                }
                &::after {
                    @include g.setIconFont('\e900');
                    position: absolute;
                    top: 0;
                    right: 12px;
                    bottom: 0;
                    margin: auto;
                    height: fit-content;
                    font-size: 10px;
                    color: g.$color-black-a060;
                }
            }
        }
    }
}

@include g.pc {
    .faq-nav,
    .faq-section {
        width: g.$content-width-pc;
        margin-left: auto;
        margin-right: auto;
    }
    .faq-nav {
        & > ul {
            display: grid;
            gap: 12px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    .faq-section {
        margin-top: 56px;
        &:last-child {
            margin-top: 58px;
        }
    }
}

@include g.sp {
    .faq-nav,
    .faq-section {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .faq-nav {
        & > ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            & > li {
                width: calc((100% - 11px) / 2);
                &:nth-child(n+3) {
                    margin-top: 11px;
                }
            }
        }
    }
    .faq-section {
        margin-top: 48px;
    }
}

// アンカーリンク用の設定
@include g.sp {
    @for $i from 1 to 11 {
        #h#{$i} {
            padding-top: 68px;
            margin-top: -20px;
        }
    }
    #reservoir {
        padding-top: 68px;
        margin-top: -20px;
    }
}
</style>
