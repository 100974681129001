import EventEmitter from 'wolfy87-eventemitter'

import { TransitionEffect } from '../TransitionEffect'

import { backfaceFixed } from '../../util/backfaceFixed'

export class Introduction extends EventEmitter {
	private introductionElm: HTMLElement | null = null
	private btnElms: NodeListOf<HTMLButtonElement> | null = null

	private transitionEffect: TransitionEffect

	constructor() {
		super()

		this.transitionEffect = new TransitionEffect()

		this.introductionElm = document.querySelector('.introduction')
		this.btnElms = document.querySelectorAll('.introduction_box_item')
	}

	public init(): void {
		if (this.introductionElm === null) return

		this.introductionElm.style.display = 'flex'

		if (this.btnElms) {
			this.btnElms.forEach((btnElm: HTMLButtonElement) => {
				btnElm.addEventListener('click', this.handleClick.bind(this))
			})
		}

		backfaceFixed(true)

		this.introductionElm.setAttribute('data-anime', 'true')
	}

	private async handleClick(e: MouseEvent) {
		const target = e.currentTarget as HTMLButtonElement
		const type = target.getAttribute('data-type')

		this.emit('onIntroductionClick', type)

		if (type === null) return

		await this.transitionEffect.enter(type)

		this.destroy()
	}

	public destroy(): void {
		if (this.introductionElm) {
			this.introductionElm.style.display = 'none'
			this.introductionElm.remove()
			backfaceFixed(false)

			this.transitionEffect.destroy()
		}
	}
}
