export class EnjoyPage {
	// イベントの種類を知ろうタブ
	private tabBtn: NodeListOf<HTMLButtonElement> | null = null
	private tabElm: HTMLElement | null = null

	constructor() {
		this.tabBtn = document.querySelectorAll('.eventEnjoy_event_tab_button')
		this.tabElm = document.querySelector('.eventEnjoy_event_tab')

		this.init()
	}

	private init() {
		if (this.tabBtn) {
			this.tabBtn.forEach((btnElm: HTMLButtonElement) => {
				btnElm.addEventListener('click', (e: Event) => {
					const target = e.currentTarget as HTMLButtonElement
					const index = Number(target?.getAttribute('data-index'))
					this.changeEventTab(index)
				})
			})
		}
	}

	private changeEventTab(index: number) {
		this.tabElm?.setAttribute('data-current-tab', `${index}`)
	}
}
