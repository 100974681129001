<template>
  <system-page :page-settings="page">
    <div class="c-content">
      <p class="u-mb-lg">
        入力されたメールアドレスに会員登録のメールをお送りしました。<br>
        お送りしたメールから、会員登録を行なってください。
      </p>
      <p class="u-mb-lg">
        しばらくたってもメールが届かない場合は、以下のご確認をお願いいたします。<br>
        ・⼊⼒内容に間違いはないか<br>
        ・ご利⽤のキャリアの設定によって、メールが「迷惑メール」に分類されていないか<br>
        ・「info@mail.players.pokemon-card.com」からのメールが受信できる設定になっているか<br>
        ・ご⼊⼒いただいたメールアドレスで、すでに「ポケモンカードゲーム プレイヤーズクラブ」に登録を⾏なっていないか
      </p>
      <p class="u-mb-lg">
        上記の問題が解決しない場合、「<a href="https://www.pokemon-card.com/contact/" target="_blank">ポケモンカードゲーム サポートデスク</a>」にお問い合わせ下さい。
      </p>
    </div>
    <div class="u-text-center to-register">
      <router-link
        to="/login/register/mail"
        class="c-btn c-btn-primary-outline"
      >新規会員登録</router-link>
    </div>
  </system-page>
</template>

<script>
import SystemPage from '@/component/SystemPage.vue'
export default {
  name: 'MailRegisterCompletePage',
  components: {
    SystemPage,
  },
  data: function() {
    return {
      page: {
        title: 'メール送信完了',
        desc: 'ポケモンカードゲーム プレイヤーズクラブ メール送信完了のページ。',
        breadcrumb: [
          { name: '新規会員登録', path: '/login/register/mail' },
        ],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/style/global' as g;

.to-register {
  line-height: 1;
}
@include g.pc {
  .c-content {
    margin-bottom: 55px;
    text-align: center;
  }
}

@include g.sp {
  .c-content {
    margin-bottom: 50px;
  }
}
</style>
