/**
 * スクロールを停止しスクロール位置を固定する関数
 * @param {boolean} fixed trueの場合固定、falseで固定を解除
 * @returns
 */
export const backfaceFixed = (fixed: boolean) => {
	// モーダルが表示している状態から次のモーダルが開いた場合はその位置にモーダルを固定する
	const state = document.body.getAttribute('data-hidden')
	if (state == 'true' && fixed) return

	const scrollingElement = () => {
		const browser = window.navigator.userAgent.toLowerCase()
		if ('scrollingElement' in document)
			return document.scrollingElement as HTMLElement
		if (browser.indexOf('webkit') > 0) return (document as Document).body
		return (document as Document).documentElement
	}

	/**
	 * 変数にスクロール量を格納
	 */
	const scrollY = fixed
		? scrollingElement().scrollTop
		: parseInt(document.body.style.top || '0')

	/**
	 * CSSで背面を固定
	 */
	const styles: {
		[key: string]: string
	} = {
		height: '100vh',
		left: '0',
		overflow: 'hidden',
		position: 'fixed',
		top: `${scrollY * -1}px`,
		width: '100vw',
	}

	Object.keys(styles).forEach((key: any) => {
		document.body.style[key] = fixed ? styles[key] : ''
	})

	document.documentElement.style.height = fixed ? '100vh' : ''

	document.body.setAttribute('data-hidden', `${fixed}`)

	/**
	 * 背面固定解除時に元の位置にスクロールする
	 */
	if (!fixed && scrollY != 0) window.scrollTo(0, scrollY * -1)
}
