import { wait } from '../../util/wait'

export class TransitionEffect {
	private elm: HTMLElement | null = null
	private mainElm: HTMLElement | null = null

	constructor() {
		this.elm = document.querySelector('.transition')
		this.mainElm = document.querySelector('.eventTop')
		this.mainElm?.setAttribute('data-transition', 'ready')
	}

	public async enter(type: string) {
		this.elm?.setAttribute('data-type', `${type}`)
		this.mainElm?.setAttribute('data-transition', 'start')

		if (type === 'enjoy') {
			this.elm?.setAttribute('data-transition', 'left-before')

			await wait(10)
			this.elm?.setAttribute('data-transition', 'center')

			await wait(1200)
			this.elm?.setAttribute('data-transition', 'right')
		} else {
			this.elm?.setAttribute('data-transition', 'right-before')

			await wait(10)
			this.elm?.setAttribute('data-transition', 'center')

			await wait(1200)
			this.elm?.setAttribute('data-transition', 'left')
		}

		this.mainElm?.setAttribute('data-transition', 'end')
	}

	async destroy() {
		await wait(10)
		this.mainElm?.setAttribute('data-transition', 'end')

		if (this.elm) {
			await wait(600)
			this.elm.remove()
		}
	}
}
